import { Modal } from '@abyss/web/ui/Modal';
import { useTranslation } from 'react-i18next';

import { adobeModalCloseTrackEvent } from '../AdobeTagging/adobeModalCloseTrackEvent';
import { Constants } from '../Constants';
import { ModalWithTracking } from '../ModalWithTracking/ModalWithTracking';

type CallModalProps = {
  title?: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: React.ReactNode;
};

export const CallModal = ({
  title,
  isOpen,
  setIsOpen,
  children,
}: CallModalProps) => {
  const { t } = useTranslation();
  return (
    <ModalWithTracking
      analyticsInfo={{
        linkName: title ?? 'Call Modal',
        modalName: title ?? 'Call Modal',
        location: `body:call-modal`,
      }}
      data-auto-testid="call-modal"
      data-testid="call-modal"
      isOpen={isOpen}
      onClose={() => {
        adobeModalCloseTrackEvent('close click');
        setIsOpen(false);
      }}
      title={`${t('Call')}`}
      trackingType={Constants.ADOBE_TRACKING.MODAL_WITH_LINK_TRACKING}
    >
      <Modal.Section>{children}</Modal.Section>
    </ModalWithTracking>
  );
};
